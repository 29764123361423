.temperature-detail {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.temperature-detail h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.detail-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.detail-card {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
}

.detail-item {
  margin-bottom: 15px;
}

.detail-label {
  font-weight: bold;
  color: #555;
  display: block;
  margin-bottom: 5px;
}

.detail-value {
  color: #333;
}

.temperature {
  font-size: 1.5em;
  font-weight: bold;
  color: #0077be;
}

.map-container {
  flex: 1;
  min-width: 300px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .detail-container {
    flex-direction: column;
  }

  .detail-card {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
