.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
}

.privacy-policy h1 {
  font-size: 2em;
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 1.5em;
  color: #2980b9;
  margin-top: 30px;
}

.privacy-policy p {
  margin-bottom: 15px;
}

.privacy-policy ul {
  padding-left: 20px;
  margin-bottom: 15px;
}

.privacy-policy li {
  margin-bottom: 10px;
}

.privacy-policy a {
  color: #3498db;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}
