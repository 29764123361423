.app-footer {
  background-color: #0077be;
  padding: 20px 0;
  margin-top: 40px;
  color: white;
  border-radius: 8px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-content p {
  margin: 0;
  font-size: 14px;
}

.footer-content nav {
  display: flex;
  align-items: center;
}

.footer-content nav a {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  font-size: 14px;
}

.footer-content nav a:hover {
  text-decoration: underline;
}

.footer-content nav a:first-child {
  margin-left: 0;
}
