body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f8ff;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #0077be;
  color: white;
  padding: 10px 0;
  text-align: center;
  border-radius: 8px;
}

header a {
  color: white;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: white;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}

li a {
  color: #0077be;
  text-decoration: none;
}
