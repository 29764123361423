.temperature-list {
  padding: 20px;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.controls input {
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.controls button {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  background-color: #0077be;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 600px) {
  .controls {
    flex-direction: row;
  }
  .controls button {
    margin-bottom: 0;
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.card h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.temperature {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.card p {
  margin: 5px 0;
}

.cold {
  background-color: #e6f3ff;
}

.moderate {
  background-color: #fff9e6;
}

.warm {
  background-color: #fff0e6;
}

.card button {
  margin-right: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.card a {
  display: inline-block;
  padding: 10px;
  background-color: #0077be;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.favorite-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 5px;
  margin-right: 10px;
}

.favorite-btn:hover {
  transform: scale(1.2);
}

.favorite-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 190, 0.3);
}

.favorite-btn.is-favorite {
  animation: pulse 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.error-message {
  color: red;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.error-message.hide {
  opacity: 0;
}

.stars-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}
