.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.slide-exit {
  transform: translateX(0);
}
.slide-exit-active {
  transform: translateX(-00%);
  transition: transform 300ms ease-in-out;
}
