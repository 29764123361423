.App {
  text-align: center;
  position: relative;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f8ff;
  color: #333;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #0077be;
  font-weight: bold;
}

button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #0077be;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
